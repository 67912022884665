<template>
  <v-slide-group v-model="model" class="mb-6" show-arrows>
    <v-slide-item v-for="(item, i) in modalities" v-slot="{ active }" :key="i">
      <v-btn
        class="text-none mx-2 pl-1"
        active-class="primary"
        :input-value="active"
        :plain="!active"
        :disabled="disabled"
        height="38"
        depressed
        small
        @click="submit(item.id, i)"
      >
        <v-avatar class="mr-2" size="32" rounded>
          <v-img :src="item.thumb" :aspect-ratio="4 / 4" />
        </v-avatar>

        <span class="truncate" v-text="item.name" />
      </v-btn>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      model: -1,
      queryKey: "gameId",
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.handleCurrent();
  },

  watch: {
    modalities() {
      this.handleCurrent();
    },
  },

  computed: {
    ...mapState(["modalities"]),
  },

  methods: {
    submit(id, index) {
      this.model = index;

      const query = {
        ...this.$route.query,
      };

      if (id !== query[this.queryKey]) query[this.queryKey] = id;
      else {
        delete query[this.queryKey];
        this.model = -1;
      }

      this.$router.replace({ query: query }).catch({
        /* ignore */
      });

      this.$emit("input");
    },

    handleCurrent() {
      const query = this.$route.query[this.queryKey];

      if (query) this.model = this.modalities.findIndex((e) => e.id === query);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .v-slide-group__prev {
  min-width: 16px !important;
}

:deep() .v-slide-group__next {
  min-width: 16px !important;
}

.truncate {
  max-width: 95px;
  white-space: nowrap;
  overflow: hidden;
}
</style>

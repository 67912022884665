<template>
  <v-container class="pa-0">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <div
          class="secondary rounded-lg mt-8"
          style="height: 24px; width: 8px"
        />

        <v-card
          class="py-3 py-md-6 px-4 px-md-8 mb-4"
          color="surface"
          width="100%"
          flat
          tile
        >
          <div
            class="d-flex flex-column flex-md-row align-md-center justify-space-between mb-6"
          >
            <div class="d-flex align-center mb-2 mb-md-0">
              <back-button class="mr-2" />

              <div class="text-h5 font-weight-bold flex-grow-1">
                <span class="saira"> Minhas Equipes </span>
              </div>
            </div>

            <v-btn
              class="rounded-l-0 rounded-r-lg"
              color="primary"
              outlined
              to="/leagues"
            >
              <v-icon left>mdi-account-multiple</v-icon> Criar uma Equipe
            </v-btn>
          </div>

          <!--Games Filters-->
          <GamesChips :disabled="loading" />

          <loader-content v-if="loading" />

          <section v-else>
            <v-data-table
              class="transparent"
              :headers="headers"
              :items="teams"
              :items-per-page="pagination.itemsPerPage"
              hide-default-footer
              disable-sort
              @dblclick:row="($event, { item }) => handleSelectTeam(item)"
            >
              <template v-slot:[`item.team`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar
                    class="pointer mr-3"
                    size="32"
                    rounded
                    v-ripple
                    @click="handleSelectTeam(item)"
                  >
                    <v-img :src="item.logo" contain />
                  </v-avatar>

                  <span
                    class="text--body-4 primary--text pointer text-decoration-underline"
                    v-ripple
                    v-text="item.name"
                    @click="handleSelectTeam(item)"
                  />
                </div>
              </template>

              <template v-slot:[`item.league`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar class="pointer mr-3" size="32" v-ripple rounded>
                    <v-img
                      :src="
                        item.league.logo ||
                        require('@/assets/leagues/default-league-logo.svg')
                      "
                      contain
                      @click="handleSelectLeague(item)"
                    />
                  </v-avatar>

                  <span
                    class="pointer text-decoration-underline text--body-4"
                    v-text="item.league.name"
                    v-ripple
                    @click="handleSelectLeague(item)"
                  />
                </div>
              </template>

              <template v-slot:[`item.players`]="{ item }">
                <v-tooltip bottom open-delay="150" close-delay="150">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center justify-center">
                      <v-icon
                        x-small
                        v-text="'mdi-account-multiple'"
                        @dblclick.stop
                      />
                      <span
                        class="text--body-4 ms-1"
                        v-on="on"
                        v-bind="attrs"
                        v-text="`${item.team_players.length}/${item.league.x}`"
                        @dblclick.stop
                      />
                    </div>
                  </template>
                  <template v-slot:default>
                    <div class="d-flex flex-column mx-2">
                      <div
                        v-for="(player, index) of item.team_players"
                        :key="index"
                        class="d-flex align-center mt-1"
                      >
                        <v-icon
                          x-small
                          v-text="
                            `${
                              item.userId === player.user.id
                                ? 'mdi-shield-account'
                                : 'mdi-account'
                            }`
                          "
                        />
                        <span
                          class="text--body-4 ms-1"
                          v-text="player.user.nickname"
                        />
                      </div>
                    </div>
                  </template>
                </v-tooltip>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <span
                  class="text--body-4"
                  v-text="formatDate(item.createdAt)"
                  @dblclick.stop
                />
              </template>
            </v-data-table>
          </section>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getMyTeams } from "@/services/teams";
import { formatDate } from "@/utils";
import GamesChips from "@/components/games/GamesChips";

export default {
  data() {
    return {
      loading: true,
      pagination: {
        page: 0,
        pageCount: 1,
        itemsPerPage: 20,
        total: 0,
      },
      headers: [
        {
          text: "Equipe",
          value: "team",
        },
        {
          text: "Liga",
          value: "league",
        },
        {
          text: "Participantes",
          value: "players",
          align: "center",
        },
        {
          text: "Criado em",
          value: "createdAt",
        },
      ],
      teams: [],
    };
  },

  components: {
    GamesChips,
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    [`$route.query`](value) {
      this.handleQuery(value);
    },
  },

  computed: {
    ...mapState(["localUser", "modalities"]),
  },

  methods: {
    formatDate,

    async getData(filters = {}) {
      try {
        this.loading = true;

        const payload = { ...filters };

        await getMyTeams(payload).then((res) => (this.teams = res));
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    isLeader(player) {
      return this.localUser.id === player.id;
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 0;
      this.getData(value);
    },

    handleSelectTeam(item) {
      this.$router.push({ path: `/team-details/${item.id}` });
    },

    handleSelectLeague(item) {
      this.$router.push({ path: `/league-details/${item.league.id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  max-width: 95px;
  white-space: nowrap;
  overflow: hidden;
}
</style>

import { request } from "./index";

export const getTeams = (data) => request("get", `/teams`, { params: data });

export const getTeam = (id) => request("get", `/teams/${id}`);

export const createTeam = (data) => request("post", "/teams", data);

export const joinTeamCode = (data) => request("post", "/teams/join/code", data);

export const joinTeamPass = (data) => request("post", "/teams/join/pass", data);

export const leaveTeam = (data) => request("put", `/teams/${data}/leave`);

export const removePlayer = (data) =>
  request("post", `/teams/${data.id}/players/remove`, data.body);

export const getMyTeams = (data) =>
  request("get", `/teams/all/me`, { params: data });

export const updateTeam = (data) =>
  request("put", `/teams/${data.id}`, data.body);
